<template>
  <div>
    <div class="customheaderitem">
      <div class="d-flex justify-content-between maincontent pl-2 pr-3">
        <img src="@/assets/images/logo/logo5px.svg" width="220" class="dark-use-white" />
        <img src="@/assets/images/logo/premium.svg" width="220" class="dark-use-white"  />
      </div>
    </div>
      <div class="card noshadow">
    <div class="card-header flex-column">
      <div class="row flex-fill w-100">
        <div class="col-sm-4" @click="setTable('is_immediate')">
          <div
            class="widget-statics2 btn p-05 btn-sm btn-block bg-danger"
            :class="{ active: datasource.active == 'is_immediate' }"
          >
            <div class="text-uppercase mb-05rem h5 mtext">Acil</div>
            <div class="badge badge-light-danger">
              {{ datasource.statics.count_is_immediate }}
            </div>
          </div>
        </div>
        <div class="col-sm-4" @click="setTable('is_hasproblem')">
          <div class="widget-statics2 btn p-05 btn-sm btn-block"
           :class="{ active: datasource.active == 'is_hasproblem' }"
          >
            <div class="text-uppercase mb-05rem h5 mtext">Problemli Cihaz</div>
            <div class="badge badge-light-danger">
              {{ datasource.statics.count_is_hasproblem }}
            </div>
          </div>
        </div>
        <div class="col-sm-4" @click="setTable('return_process')">
          <div class="widget-statics2 btn p-05 btn-sm btn-block" 
           :class="{ active: datasource.active == 'return_process' }">
            <div class="text-uppercase mb-05rem h5 mtext">İade</div>
            <div class="badge badge-light-danger">
              {{ datasource.statics.count_return_process }}
            </div>
          </div>
        </div>
      </div>

      <div class="row flex-fill w-100 mt-1">
        <div class="col-sm-4" @click="setTable('analysis_process')"  >
          <div class="widget-statics2 btn p-05 btn-sm btn-block" 
           :class="{ active: datasource.active == 'analysis_process' }">
            <div class="mb-05 h5 mtext">T. DEĞERLENDİRME BEKLİYOR</div>
            <div class="badge badge-light-danger">
              {{ datasource.statics.count_analysis_process }}
            </div>
          </div>
        </div>
        <div class="col-sm-4" @click="setTable('repair_process')">
          <div class="widget-statics2 btn p-05 btn-sm btn-block" 
          :class="{ active: datasource.active == 'repair_process' }"
           >
            <div class="mb-05rem h5 mtext">T. İŞLEM AŞAMASINDA</div>
            <div class="badge badge-light-danger">
              {{ datasource.statics.count_repair_process }}
            </div>
          </div>
        </div>
        <div class="col-sm-4" @click="setTable('test_process')">
          <div class="widget-statics2 btn p-05 btn-sm btn-block"  
          :class="{ active: datasource.active == 'test_process' }">
            <div class="mb-05rem h5 mtext">TEST AŞAMASINDA</div>
            <div class="badge badge-light-danger">
              {{ datasource.statics.count_test_process }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-bodyx pt-2x firstlast-padding-2x">
      <b-table
        responsive
        striped
        hover
        small
        show-empty
        empty-text="Gösterilecek kayıt bulunmuyor"
        :items="datasource.table.items"
        :fields="datasource.table.fields"
        class="mb-0"
      >
        <template #cell(brand_name)="data">
          <div class="font-weight-semibold">{{ data.value }}</div>
          <div>{{ data.item.model_name }}</div>
        </template>
        <template #cell(number_item)="data">
          <div>
            <span class="w1 d-inline-block">Servis No:</span>
            <router-link
              :to="{
                name: 'service',
                query: { uniq: data.item.sf_item.sf_uniq },
              }"
            >
              <span class="font-weight-bold">#{{ data.item.sf_id }}</span>
            </router-link>
          </div>
          <span class="w1 d-inline-block">Cihaz No:</span>
          <span class="font-weight-bold">{{ data.item.device_id }}</span>
          <div>
            <span class="w1 d-inline-block">Barkod No:</span>
            <span class="font-weight-bold">{{ data.item.device_barcode }}</span>
          </div>
        </template>
        <template #cell(date_item)="data">
          <div>
            <div class="row">
              <div class="col-sm-5 font-weight-bold">Servis T:</div>
              <div v-if="data.item.sf_item != null" class="col-sm-7">
                {{ moment(data.item.sf_item.sf_date).format("DD.MM.YYYY") }}
              </div>
            </div>
          </div>
          <div>
            <span
              v-if="data.value.is_immediate == 1"
              class="badge badge-light-danger d-block"
            >
              ACİL ÇIKIŞ
              {{ data.value.due_date }}
            </span>
          </div>
          <div
            v-if="data.value.due_passing_day > 0"
            class="badge badge-warning d-block mt5px"
          >
            {{ data.value.due_passing_day }} GÜN GEÇİKTİ
          </div>
        </template>
        <template #cell(status_item)="data">
          <status-badge :status="data.value" />
        </template>
        <template #cell(task_items)="data">
          <div v-if="data.item.tasks.length > 0">
            <ul class="p-0 pl-1">
              <li v-for="(taskitem, indext) in data.item.tasks" :key="indext">
                <small> {{ taskitem.task_definition_text }}</small>
              </li>
            </ul>
          </div>
        </template>
        <template #cell(customer)="data">
          <div v-if="data.item.customer != null">
            <div class="d-flex align-items-center">
              <b-avatar
                size="32"
                :src="data.item.customer.profile_url"
                :text="shortUserName(data.value.user_namesurname)"
              />
              <div class="ml-1">
                <div>{{ data.item.customer.title }}</div>
                <a :href="'tel:' + data.item.customer.gsm_primary">{{
                  data.item.customer.gsm_primary
                }}</a>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="text-danger font-weight-bold">Müşteri Seçilmedi</div>
          </div>
        </template>
        <template #cell(staff_item)="data">
          <div v-if="data.value !=null">{{ data.value.user_namesurname }}</div>
        </template>
      </b-table>
    </div>
  </div>
  </div>
  
</template>

<script>
import StatusBadge from "@/views/common/StatusBadge.vue";
import { VueAutosuggest } from "vue-autosuggest";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import api from "@/service/api";
import _ from "lodash";
import { mapState, mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
    VueAutosuggest,
    vSelect,
    flatPickr,
    StatusBadge,
  },
  data() {
    return {
      datasource: {
        apiresponsedata: {
          analysis_process: [],
          delivery_send_process: [],
          delivery_take_process: [],
          is_immediate: [],
          is_hasproblem: [],
          repair_process: [],
          return_process: [],
          test_process: [],
        },
        statics: {
          count_is_immediate: 0,
          count_is_hasproblem: 0,
          count_delivery_take_process: 0,
          count_analysis_process: 0,
          count_repair_process: 0,
          count_test_process: 0,
          count_delivery_send_process: 0,
          count_return_process: 0,
        },
        table: {
          items: [],
          fields: [],
        },
        active: "",
        pooling :null
      },
    };
  },
  created() 
  {
    if( this.$route.query.type!=null )
    {
      this.datasource.active = this.$route.query.type
    }
    else
    {
       this.datasource.active = 'is_immediate';
    }
    this.getDeviceList();
    this.pooling = setInterval(()=>
    {
      this.getDeviceList();
    },1000 * 60)
  },
  beforeDestroy()
  {
    clearInterval(this.pooling);
  },
  methods: {
    getDeviceList() {
      let postData = { is_immediate: "1" };
      api.action("reportDevices", postData).then((q) => {
        if (q.success) {
          this.datasource.apiresponsedata = q.data;
          let data = q.data;

          Object.keys(data).forEach((item) => {
            data[item].forEach((q) => {
              let due_date_parsed = moment(q.due_date).isValid()
                ? moment(q.due_date).format("DD.MM.YYYY")
                : null;

              let due_passing_day = null;
              if (due_date_parsed != null) {
                due_passing_day = moment().diff(moment(q.due_date), "days");
              }

              q.date_item = {
                due_date: due_date_parsed,
                is_immediate: q.is_immediate,
                due_passing_day: due_passing_day,
              };
            });
          });

          this.datasource.statics.count_is_immediate = data.is_immediate.length;
          this.datasource.statics.count_is_hasproblem =
            data.is_hasproblem.length;
          this.datasource.statics.count_delivery_take_process =
            data.delivery_take_process.length;
          this.datasource.statics.count_analysis_process =
            data.analysis_process.length;
          this.datasource.statics.count_repair_process =
            data.repair_process.length;
          this.datasource.statics.count_test_process = data.test_process.length;
          this.datasource.statics.count_delivery_send_process =
            data.delivery_send_process.length;
          this.datasource.statics.count_return_process =
            data.return_process.length;
 
        this.setTable(this.datasource.active)

        }
      });
    },
    moment: function (item) {
      return moment(item);
    },
    shortUserName(username) {
      if (username == null) return;
      let result = "";
      if (username.includes(" ")) {
        result = username.split(" ")[0][0] + "" + username.split(" ")[1][0];
      } else {
        result = username[0];
      }
      return result;
    },
    getDefaultFields() {
      return [
        {
          key: "brand_name",
          label: "Marka",
          sortable: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "number_item",
          label: "",
          sortable: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "date_item",
          label: "Tarih",
          sortable: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "task_items",
          label: "Yapılacaklar",
          sortable: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
        {
          key: "status_item",
          label: "Durum",
          sortable: true,
          thClass: "text-left",
          tdClass: "text-left",
        },
       {
          key: "staff_item",
          label: "Sorumlu",
          sortable: false,
          thClass: "text-left",
          tdClass: "text-left",
        },
      ];
    },
    setTable(target) {
      this.datasource.active = target;
      if (target == "is_immediate") {
        this.datasource.table.items =
          this.datasource.apiresponsedata.is_immediate;
        this.datasource.table.fields = this.getDefaultFields();
      }
      if (target == "is_hasproblem") {
        this.datasource.table.items =
          this.datasource.apiresponsedata.is_hasproblem;
        this.datasource.table.fields = this.getDefaultFields();
      }
      if (target == "delivery_take_process") {
        this.datasource.table.items =
          this.datasource.apiresponsedata.delivery_take_process;
        this.datasource.table.fields = this.getDefaultFields();
      }
      if (target == "analysis_process") {
        this.datasource.table.items =
          this.datasource.apiresponsedata.analysis_process;
        this.datasource.table.fields = this.getDefaultFields();
      }
      if (target == "repair_process") {
        this.datasource.table.items =
          this.datasource.apiresponsedata.repair_process;
        this.datasource.table.fields = this.getDefaultFields();
      }
      if (target == "test_process") {
        this.datasource.table.items =
          this.datasource.apiresponsedata.test_process;
        this.datasource.table.fields = this.getDefaultFields();
      }
      if (target == "delivery_send_process") {
        this.datasource.table.items =
          this.datasource.apiresponsedata.delivery_send_process;
        this.datasource.table.fields = this.getDefaultFields();
      }
      if (target == "return_process") {
        this.datasource.table.items =
          this.datasource.apiresponsedata.return_process;
        this.datasource.table.fields = this.getDefaultFields();
      }
    },
  },
  computed: {
    ...mapState({
      sf: (state) => state.sf,
    }),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.customheaderitem
{
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%);
  margin-bottom:1rem;
}
.noshadow
{
  box-shadow: none !important;
}

.w1 {
  width: 7rem;
}
.mt5px {
  margin-top: 5px;
}
.widget-statics2 {
  .mtext
  {
    font-size:1.5rem !important;
  }
  .badge {
    font-size: 3rem !important;
    padding-left:3rem !important;
    padding-right:3rem !important;
  }
  border: 1px solid #e8e3e3;
  background-color: #f8f8f8 !important;
  &.active {
    background-color: #d81116 !important;
    border-color: #d81116;
    color: #fff !important;
    .mtext {
      color: #fff;
    }
    .badge {
      background-color: #000;
      color: #fff !important;
    }
  }
  
  
}
</style>
 